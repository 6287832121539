@import './icons.scss';

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// RTL Mode
// @import "./bootstrap.scss";
// @import "./app-rtl.scss";

// Light Theme
@import './bootstrap.scss';
@import './app.scss';
@import './custom.scss';

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}
.noti-icon .badge {
  left: 23px;
}
.dataTables_filter,
.dataTables_paginate {
  float: right;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 30px;
}
// kanban board
.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.task-box {
  border: 1px solid $border-color;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.ReactModal__Overlay {
  z-index: 1001 !important;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}
.external-event {
  &:hover {
    cursor: pointer;
  }
}
a:hover {
  cursor: pointer;
}
.rating-container {
  background-color: transparent !important;
}
.input-group-append {
  z-index: 0;
}
.input-color {
  color: $input-color !important;
}
.sketch-picker {
  position: absolute;
  z-index: 1;
}
.rangeslider__fill {
  background-color: $primary !important;
}
.rdw-specialchar-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap;
}
.rdw-specialchar-modal {
  overflow: auto;
  position: absolute;
  top: 35px;
  left: -250px;
  display: flex;
  flex-wrap: wrap;
  width: 450px;
  height: 180px;
  border: 1px solid #F1F1F1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #BFBDBD;
}
.rdw-specialchar-categories{
  display: block;
  width: 100%;
}
.rdw-specialchar-categories button {
  text-transform: capitalize;
  padding: 4px;
  margin: 1px;
  background: #f1f1f1;
  cursor: pointer;
  font-weight: bold;
  border: none;
  border-bottom: 2px solid #096be5; /*#838383*/
  font-size: 0.8rem;
}
.rdw-specialchar-symbols{
  display: block;
  width: 100%;
  height: 100%;
}
.rdw-specialchar-icon {
  margin: 2.5px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  font-size: 18px;
  display: inline-block;
  justify-content: center;
  align-items: center;
}

.DraftEditor-editorContainer {
  background-color: #fff !important;
}
.rdw-colorpicker-modal{
  width: 250px !important;
}
.rdw-colorpicker-option{
  margin: 1px !important;
}