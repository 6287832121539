.noti-icon .badge {
  left: 23px;
}

.mt-2 {
  margin-top: 20px !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}

.button-arrow {
  background: #fff !important;
  color: #1a70b7 !important;
  border: none;
}


.button-arrow:active,
.button-arrow:focus,
.button-arrow:disabled {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.dz-message {
  text-align: center;
  padding: 100px;
}

.fcYNFc {
  background-color: #ffffff !important;
}

.task-box {
  border: 1px solid #e1e1e6;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}

.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

/* Reset page */
.reset-page-message {
  background-color: #fffcd7;
  padding: 15px;
  font-size: 16px;
  text-align: center;
  border-radius: 4px;
}

/* Login page */
.login-form * {
  font-family: 'Lato', sans-serif;
}

.bg-soft-primary {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.35);
  background-image: linear-gradient(68deg, #ea6ac2 -45%, #196bb5 37%, #7dff7a 157%);
}

.text-white {
  color: #ffffff;
}

.avatar-title {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.35);
  background-image: linear-gradient(32deg, #ea6ac2 -63%, #196bb5 44%, #7dff7a 201%);
}

body[data-sidebar='dark'] .vertical-menu {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.35);
  background-image: linear-gradient(9deg, #ea6ac2 -74%, #196bb5 50%, #7dff7a 230%);
}

.sidebar-icon {
  margin-right: 10px;
  margin-top: -4px;
}

// general
.agora-jost {
  font-family: 'Jost', sans-serif;
}

.agora-lato,
.card-title-desc {
  font-family: 'Lato', sans-serif;
}

.page-item.active .page-link {
  background-color: #196bb5;
  border-color: #196bb5;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.35);
  background-image: linear-gradient(32deg, #ea6ac2 -63%, #196bb5 44%, #7dff7a 201%);
}

.avatar-title {
  text-transform: capitalize;
}

.avatar-lg .avatar-title,
.avatar-xl .avatar-title {
  font-size: 3em;
}

.br-10 {
  border-radius: 10px;
}

.filter-field {
  min-width: 175px;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
}

.subject-status {
  margin-left: 30px;
}

.waves-effect.active {
  background-color: #ffffff35;
}

body[data-sidebar='dark'] .navbar-brand-box {
  background: transparent;
}

body[data-sidebar='dark'] #sidebar-menu ul li a,
body[data-sidebar='dark'] #sidebar-menu ul li a i,
body[data-sidebar='dark'] .menu-title {
  color: #ffffff;
}

.agora-filter-buttons {
  padding-top: 28px;
}

.agora-form-buttons {
  padding-bottom: 30px;
}

.agora-button {
  min-width: 90px;
  background-image: linear-gradient(68deg, #ea6ac2 -45%, #196bb5 37%, #7dff7a 157%);
  padding-left: 20px;
  padding-right: 20px;
  font-family: 'Jost', sans-serif;
  border: none;
}

.agora-decline-button {
  min-width: 90px;
  border-radius: 12px;
  background-image: linear-gradient(262deg, #ea6a76, #a10d4b -5%, #ea6a76 157%);
  font-family: 'Jost', sans-serif;
  border: none;
}

.agora-button.cancel-button {
  border: solid 1px #1b6db3;
  color: #1b6db3 !important;
  background-image: none;
  background-color: transparent;
  margin-right: 15px;
  padding: 6.5px 15px;
}

.agora-button.border-radius {
  border-radius: 15px;
}

.relative {
  position: relative;
}

.agora-action-button {
  border: none !important;
  color: #495057 !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.back-button {
  color: #555b6d;
  cursor: pointer;
}

.agora-link-color,
.agora-link-color:hover {
  color: #00adef;
}

.color-white {
  color: #ffffff;
}

.left-right-button,
.left-right-button.disabled,
.left-right-button:hover,
.left-right-button:focus,
.left-right-button:active:hover,
.left-right-button:active {
  background-color: #ced4da;
  border-color: #ced4da;
  width: 100%;
  border-radius: 0;
  height: 600px;
  box-shadow: none;
}

.left-right-button.disabled {
  pointer-events: all;
}

.left-right-button.disabled:hover {
  cursor: not-allowed;
}

.rdw-image-modal {
  left: auto;
  right: 0;
}

.bigger-status > .badge {
  font-size: 16px !important;
  padding: 7px 20px;
}

.agora-grey-color {
  color: #7f7f7f;
}

.agora-dark-grey-color {
  color: #495057;
}

.action-section {
  vertical-align: middle !important;
  text-align: center;
  padding-top: 0px;
}


.action-section:focus,
.action-section:active {
  outline: none !important;
  box-shadow: none;
  color: #1974bb;
}

.task-assignee h5 {
  color: #495057;
}

.task-assignee {
  .task-assignee-heading {
    display: flex;
    justify-content: space-between;

    span {
      margin: 0px;
      cursor: pointer;

      img {
        margin-top: 0px;
        margin-bottom: 0px;
        width: 22px;
      }
    }
  }
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
}

.task-assignee-selected {
  background-image: linear-gradient(68deg, #ea6ac2 -45%, #196bb5 37%, #7dff7a 157%);
  color: 'white';
  border-radius: 20px;
}

.task-assignee img {
  width: 55px;
  border-radius: 50%;
  margin-top: 25px;
  display: block;
  float: left;
  margin-bottom: 25px;
}

.task-assignee span {
  display: block;
  font-size: 16px;
  margin-left: 10px;
  float: left;
  margin-top: 40px;
}

.task-content-box {
  border: solid 1px #ccc;
  border-radius: 4px;
  padding: 7px;
  margin-top: 10px;
  //height: 150px;
}

.question-height-container {
  //height: 310px !important;
}

.task-search-assignee-list {
  display: block;
  height: 170px;
  overflow-y: scroll;
}

.obtained-marks {
  width: 35px;
}

.main-image {
  border: solid 1px #ccc;
  border-radius: 3px;
  text-align: center;
  position: relative;
}

.main-image img {
  max-height: 300px;
}

.marking-scheme-images {
  border: solid 1px #ccc;
  border-radius: 3px;
  text-align: center;
  position: relative;
}

.image-thumbnails {
  margin-top: 10px;
}

.image-thumbnails img {
  width: 45px;
  margin-right: 7px;
  border-radius: 5px;
}

.active-image {
  border: solid 1px blue;
}

.image-arrows {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 5px 5px;
  background: #ffffff90;
}

.image-arrows span {
  cursor: pointer;
}

.image-arrows img {
  margin-right: 7px;
  margin-left: 7px;
}

// auth pages
.auth-pages-agora-icon {
  margin-top: -5px;
}

// add user
.status-switch {
  margin-top: 9px;
}

.custom-switch .custom-control-label::before {
  width: 3rem;
  height: 1.25rem;
}

.custom-switch .custom-control-label::after {
  // left: calc(-1.25rem + 2px);
  width: calc(1.25rem - 5px);
  height: calc(1.25rem - 5px);
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(1.75rem);
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #45c38f;
  background-color: #45c38f;
}

.status-switch .custom-control-label {
  padding-left: 20px;
  padding-top: 2px;
  font-size: 14px;
  font-weight: 100;
}

// chapters
.chapter-icon {
  float: left;
  margin-right: 5px;
}

// Add subject
.subject-icon-dz {
  padding: 8px !important;
  padding-bottom: 45px !important;
}

.dropzone {
  cursor: pointer;
}

.dropzone.subject-image-dropzone {
  height: 270px;
  width: 270px;
}

.subject-image-dropzone > .dz-message {
  padding: 30px 20px;
}

#subject-icon-preview {
  .dz-image-preview {
    height: 270px;
    width: 270px;
    border: 2px dashed #ced4da !important;

    & img {
      width: 100%;
      height: auto;
    }
  }
}

// view-subject
.subject-icon {
  width: 100px;
  float: left;
  height: 100px;

  img {
    width: 100%;
    border-radius: 50%;
  }
}

.subject-info {
  padding-top: 10px;
  width: calc(100% - 120px);
  margin-left: 20px;
  float: left;
}

.subject-info-section {
  border-bottom: solid 1px #eff2f7;
  padding-bottom: 20px;
}

.section-top {
  border-top: solid 1px #eff2f7;
}

.section-bottom {
  border-bottom: solid 1px #eff2f7;
}

.section-left {
  border-left: solid 1px #eff2f7;
}

.section-right {
  border-right: solid 1px #eff2f7;
}

.subject-creator-info-section {
  padding-top: 20px;
  color: #666666;

  .subject-creator-info {
    p {
      margin-bottom: 5px;
    }
  }
}

//profile
.btn.change-image-button,
.btn.change-image-button:hover,
.btn.change-image-button:focus,
.btn.change-image-button:active {
  outline: none;
  border: none;
  box-shadow: none;
  background: transparent;
  color: #1768b1 !important;
}

.profile-image {
  max-width: 120px;
}

// mobile
.marvel-device {
  transform-origin: 30% 0;
}

.marvel-device.iphone8 .screen {
  padding: 10px;
}

.device-content {
  text-align: initial;
  max-height: -webkit-fill-available;
}

.study-guide-rte .rich-text-editor {
  height: 450px;
}

.answer_container{
 flex-direction: column;

}

.device-option {
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.49);
  bottom: -0.45rem;
  left: -0.75rem;
  width: 104%;
  position: relative;
  border-radius: inherit;

  span {
    height: 40px;
    width: 40px;
    border-radius: 200px 200px 200px 200px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
  }

  .correct-option {
    background-color: #638cd2;
    color: white;
  }
}

.topBar {
  display: block;
  /* background: rgb(25,106,179); */
  background: linear-gradient(90deg, rgba(25, 106, 179, 1) 0%, rgba(2, 171, 236, 1) 100%);
  width: 100%;
  height: 6rem;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
}

.screen {
  padding: 0px !important;
}

.screenCard {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  margin: 25px;
  border-radius: 25px;
  height: 29rem;
  padding: 0.5rem 0rem 0.5rem 0.75rem;
}

.progressOnScreen {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  margin: 25px;
  border-radius: 25px;
  height: 2rem;
  display: flex;
  width: 85%;
  padding: 0.5rem 0rem 0.5rem 0.75rem;

  .progress {
    width: 85%;
    margin-top: 3px;
    .bg-primary {
      background-image: linear-gradient(68deg, #ea6ac2 -45%, #196bb5 37%, #7dff7a 157%) !important;
    }
  }

  .progressText {
    text-align: center;
    width: 10%;
    color: #1a73bb;
    font-size: 14px;
    margin-top: -4px;
    margin-left: 5px;
  }
}

.cardIconCircle {
  border: 2px solid #196bb5;
  height: 35px;
  width: 35px;
  display: inline-flex;
  border-radius: 18px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cardIconCircleSm {
  border: 2px solid #196bb5;
  height: 25px;
  width: 25px;
  display: inline-flex;
  border-radius: 18px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
}

.bottomBar {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  height: 5rem;
}
