#agora-spinner-container {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 10;
  bottom: 0;
  background-color: #ffffff80;
  .agora-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 3rem;
    margin-left: -1.5rem;
    margin-top: -1.5rem;
    height: 3rem;
    border: 0.4em solid currentColor;
    border-right-color: transparent;
  }
}
