@import '../../assets/scss/theme.scss';

.radio-toolbar input[type='radio'] {
  opacity: 0;
  position: fixed;
  width: 0;
}
.radio-toolbar label {
  display: inline-block;
  background-color: $gray-300;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px;
}
.radio-toolbar input[type='radio']:checked + label {
  background-color: $primary;
  border: none;
  color: $white;
}

#radio-title {
  margin-bottom: 5px;
}
