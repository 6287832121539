.questionTagging {
  .headline {
    padding:10px;
    background: #f8f9fa;
  }
  .border-right {
    border-right: 1px solid #f8f9fa;
  }
  .question {
    &-section {
      padding: 16px;
      border-radius: 8px;
      background: #fff;
      display: flex;
      border-bottom: 1px solid #f8f9fa;
      .bxs-minus-circle {
        color: #e48080;
        font-size: 16px !important;
    }
      .bxs-check-circle {
        color: #9de480;
        font-size: 16px !important;
      }
    }
    &-status {
      text-align: center;
      line-height: 10px;
      position: absolute;
      right: 15px;
      float: right;
      width: 55px;
    }
    &-label {
      width: 90%;
      padding-right: 12px;
    }
    &-title {
      color: #bfbfbf;
      margin-bottom: 4px;
    }
    &-chapter {
      color: #495057;
    }
    &-preview {
      border: 1px solid #979797;
      height: 100%;
      border-radius: 10px;
      max-height: 700px;
      padding: 12px;
      color: #495057;
      overflow: auto;
      .subsubquestion {
        background: #e4e4e447;
        padding: 12px;
      }
    }
  }
  .preview-MCQ {
    border-radius: 10px 10px 0px 0px !important;
    border-bottom: 1px solid #fff0;
    height: calc(100% - 40px) !important;
  }

  .preview-Structured {
    border-radius: 10;
  }

  .icon-plus {
    color: #3b6cb6;
    font-size: 34px;
  }
  .icon-plus-disable {
    color: #f2f2f2;
    font-size: 34px;
  }
  .text-icon {
    font-style: italic;
    font-size: 8px;
  }
  .question-section-active {
    padding: 16px;
    border-radius: 8px;
    display: flex;
    border-bottom: 1px solid #f8f9fa;
    background-image: linear-gradient(53deg, #ac78f8 -62%, #196bb5 55%, #7dff7a 212%) !important;
    .question-title, .question-chapter, .question-status{
      color: #ffff !important;
    }
    .bxs-minus-circle, .bxs-check-circle {
      color: #ffff !important;
      font-size: 16px !important;
    }
  }
  .body-study-guides {
    height: calc(100% - 100px);
  }
  .study-guide {
    background: #e1f0ff;
    margin-bottom: 6px;
    border-radius: 5px;
    padding: 6px;
    display: flex;
    .subject {
      color: #495057;
    }
    .title {
      color: #000000;
    }
    .left {
      width: 94%;
      padding-right: 6px;
    }
  }
  .icon-remove {
    color: #3b6cb6;
    font-size: 16px;
    margin-top: 10px;
  }

  .answer-mcq-section {
    display: flex;
    width: 100%;
    height: 60px;
    padding: 10px;
    background: #ffffff;
    border-radius: 0px 0px 10px 10px;
    border-right: 1px solid #979797;
    border-bottom: 1px solid #979797;
    border-left: 1px solid #979797;
    box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.14);
    justify-content: center !important;
    .options {
      margin: 0px 12px 0px 12px;
      width: 40px;
      height: 40px;
      text-align: center;
      text-transform: capitalize;
      line-height: 37px;
      border-radius: 50%;
    }
    .option-true {
      background-image: linear-gradient(32deg, #ea6ac2 -63%, #196bb5 44%, #7dff7a 201%);
      color: #fff;
    }
    .option-false {
      background: #fff;
      color: #495057;
      border: 1px solid #d8d8d8;
    }
  }
}